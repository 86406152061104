import React from 'react'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import htmlSerializer from './src/utils/htmlSerializer'
import { repositoryConfigs } from './src/utils/prismicPreviews'

// CSS
import '@fontsource/roboto'
import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    externalLinkComponent={({ href, className, ...props }) => {
      if (href.includes('ironsauction.com')) {
        return (
          <a data-location='internal' href={href} target='_self' className={className}>
            {props.children}
          </a>
        )
      }
      return (
        <a data-location='external' href={href} target='_blank' rel='noreferrer' className={className} {...props}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => {
      return (
        <a data-location='internal' href={href} {...props}>
          {props.children}
        </a>
      )
    }}
    richTextComponents={htmlSerializer}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)
